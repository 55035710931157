import React, { useState, useEffect } from "react";
import { useStore } from "react-redux";

import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { NavLink, useNavigate } from "react-router-dom";
import AuthenticationService from "../../services/auth-service";
import AuthorizationService from "../../services/authorization-service";

const Nav = (props) => {
  // Vérifier les rôles de l'utilisateur
  const canAccessHome = AuthorizationService.readListePrincipale();
  const canAccessNcPrincipale = AuthorizationService.readListePrincipale();
  const canAccessNcPerseverance = AuthorizationService.readListePerseverance();
  const canAccessPointage = AuthorizationService.readPointage();
  const canAccessAttribution = AuthorizationService.readAttribution();
  const canAccessMaListeAppel = AuthorizationService.readMaListeAppel();
  const canAccessMonSuivi = AuthorizationService.readMonSuivi();
  const canAccessSuiviTelephonique =
    AuthorizationService.readSuiviPerseverance() ||
    AuthorizationService.readSuiviPrincipale() ||
    AuthorizationService.readMonSuivi() ||
    AuthorizationService.readSuiviAction();
  const canAccessGestionNc =
    AuthorizationService.writeMesSaisies() ||
    AuthorizationService.readMesSaisies() ||
    AuthorizationService.readPointage() ||
    AuthorizationService.writePointage() ||
    AuthorizationService.readListePrincipale() ||
    AuthorizationService.writeListePrincipale() ||
    AuthorizationService.readListePerseverance() ||
    AuthorizationService.writeListePerseverance();
  const canAccessSuiviPerseverance =
    AuthorizationService.readSuiviPerseverance();
  const canAccessSuiviPrincipale = AuthorizationService.readSuiviPrincipale();
  const canAccessCertificat = AuthorizationService.readCertificat();
  const canAccessFormations = AuthorizationService.readFormations();
  const canWriteMesSaisies = AuthorizationService.writeMesSaisies();

  const store = useStore();
  const [layout, setLayout] = useState(store.getState().layout);
  const [session] = useState(store.getState().session);

  const [show, setShow] = useState(false);
  const history = useNavigate();

  const handleClick = () => {};
  const handleLoad = () => window.location.reload();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleLogout = () => {
    AuthenticationService.logout().then((response) => {
      store.dispatch({
        type: "LOGOUT_SESSION",
        payload: undefined,
      });
    });
    history("/login");
    setShow(false);
  };

  const toggleBody = () => {
    let body = document.body;
    body.classList.toggle("layout-fullwidth");
    store.dispatch({
      type: "TOGGLE_LAYOUT",
      payload: body.classList.contains("layout-fullwidth"),
    });
  };

  useEffect(() => {
    store.subscribe(() => {
      setLayout(store.getState().layout);
    });

    if (!session) {
      store.dispatch({
        type: "LOGOUT_SESSION",
        payload: undefined,
      });
      history("/login");
    }
  }, [history, session, layout, store]);

  return (
    <React.Fragment>
      {/* 
      <div className="page-loader-wrapper">
        <div className="loader">
          <div className="m-t-30">
            <img
              src="../assets/images/icon-light.svg"
              width="48"
              height="48"
              alt="31 Jours"
            />
          </div>
          <p>Please wait...</p>
        </div>
      </div>
  */}
      <div className="overlay"></div>

      <div id="wrapper">
        <nav className="navbar navbar-fixed-top">
          <div className="container-fluid">
            <div className="navbar-left">
              <div className="navbar-btn">
                <NavLink to="/home">
                  <img
                    src="../assets/images/icon-light.svg"
                    alt="31 jours Logo"
                    className="img-fluid logo"
                  />
                </NavLink>
                <button type="button" className="btn-toggle-offcanvas">
                  <i className="lnr lnr-menu fa fa-bars"></i>
                </button>
              </div>
              <NavLink
                to="#"
                className="icon-menu btn-toggle-fullwidth"
                alt="fullwidth"
                onClick={toggleBody}
              >
                <i
                  className={
                    layout?.isFull ? "fa fa-arrow-right" : "fa fa-arrow-left"
                  }
                ></i>
              </NavLink>
            </div>

            <div className="navbar-right">
              <div id="navbar-menu">
                <ul className="nav navbar-nav">
                  <li className="dropdown dropdown-animated scale-left">
                    <NavLink
                      to="#"
                      className="dropdown-toggle icon-menu"
                      data-toggle="dropdown"
                    >
                      <i className="icon-envelope"></i>
                      <span className="notification-dot"></span>
                    </NavLink>
                    <ul className="dropdown-menu right_chat email">
                      <li>
                        <NavLink to="#">
                          <div className="media">
                            <img
                              className="media-object "
                              src="../assets/images/xs/avatar4.jpg"
                              alt=""
                            />
                            <div className="media-body">
                              <span className="name">
                                James Wert{" "}
                                <small className="float-right">Just now</small>
                              </span>
                              <span className="message">
                                Lorem ipsum Veniam aliquip culpa laboris minim
                                tempor
                              </span>
                            </div>
                          </div>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="#">
                          <div className="media">
                            <img
                              className="media-object "
                              src="../assets/images/xs/avatar1.jpg"
                              alt=""
                            />
                            <div className="media-body">
                              <span className="name">
                                Folisise Chosielie{" "}
                                <small className="float-right">12min ago</small>
                              </span>
                              <span className="message">
                                There are many variations of Lorem Ipsum
                                available, but the majority
                              </span>
                            </div>
                          </div>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="#">
                          <div className="media">
                            <img
                              className="media-object "
                              src="../assets/images/xs/avatar5.jpg"
                              alt=""
                            />
                            <div className="media-body">
                              <span className="name">
                                Ava Alexander{" "}
                                <small className="float-right">38min ago</small>
                              </span>
                              <span className="message">
                                Many desktop publishing packages and web page
                                editors
                              </span>
                            </div>
                          </div>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="#">
                          <div className="media mb-0">
                            <img
                              className="media-object "
                              src="../assets/images/xs/avatar2.jpg"
                              alt=""
                            />
                            <div className="media-body">
                              <span className="name">
                                Debra Stewart{" "}
                                <small className="float-right">2hr ago</small>
                              </span>
                              <span className="message">
                                Contrary to popular belief, Lorem Ipsum is not
                                simply random text
                              </span>
                            </div>
                          </div>
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                  <li className="dropdown dropdown-animated scale-left">
                    <NavLink
                      to="#"
                      className="dropdown-toggle icon-menu"
                      data-toggle="dropdown"
                    >
                      <i className="icon-bell"></i>
                      <span className="notification-dot"></span>
                    </NavLink>
                    <ul className="dropdown-menu feeds_widget">
                      <li className="header">You have 5 new Notifications</li>
                      <li>
                        <NavLink to="#">
                          <div className="feeds-left">
                            <i className="fa fa-thumbs-o-up text-success"></i>
                          </div>
                          <div className="feeds-body">
                            <h4 className="title text-success">
                              7 New Feedback{" "}
                              <small className="float-right text-muted">
                                Today
                              </small>
                            </h4>
                            <small>
                              It will give a smart finishing to your site
                            </small>
                          </div>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="#">
                          <div className="feeds-left">
                            <i className="fa fa-user"></i>
                          </div>
                          <div className="feeds-body">
                            <h4 className="title">
                              New User{" "}
                              <small className="float-right text-muted">
                                10:45
                              </small>
                            </h4>
                            <small>I feel great! Thanks team</small>
                          </div>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="#">
                          <div className="feeds-left">
                            <i className="fa fa-question-circle text-warning"></i>
                          </div>
                          <div className="feeds-body">
                            <h4 className="title text-warning">
                              Server Warning{" "}
                              <small className="float-right text-muted">
                                10:50
                              </small>
                            </h4>
                            <small>Your connection is not private</small>
                          </div>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="#">
                          <div className="feeds-left">
                            <i className="fa fa-check text-danger"></i>
                          </div>
                          <div className="feeds-body">
                            <h4 className="title text-danger">
                              Issue Fixed{" "}
                              <small className="float-right text-muted">
                                11:05
                              </small>
                            </h4>
                            <small>
                              WE have fix all Design bug with Responsive
                            </small>
                          </div>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="#">
                          <div className="feeds-left">
                            <i className="fa fa-shopping-basket"></i>
                          </div>
                          <div className="feeds-body">
                            <h4 className="title">
                              Aide{" "}
                              <small className="float-right text-muted">
                                11:35
                              </small>
                            </h4>
                            <small>Support.</small>
                          </div>
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Button variant="secondary" onClick={handleLoad}>
                      <i className="icon-refresh"></i>
                    </Button>
                    <Button variant="warning" onClick={handleShow}>
                      <i className="icon-power"></i>
                    </Button>
                    <Modal show={show} onHide={handleClose}>
                      <Modal.Header closeButton>
                        <Modal.Title>Déconnexion</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        Vous pouvez vous déconnecter en toute sécurité!
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                          Fermer
                        </Button>
                        <Button variant="danger" onClick={handleLogout}>
                          Déconnexion
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
        <div id="rightbar" className="rightbar">
          <ul className="nav nav-tabs-new">
            <li className="nav-item">
              <NavLink
                className="nav-link active"
                data-toggle="tab"
                to="#setting"
              >
                Settings
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" data-toggle="tab" to="#chat">
                Chat
              </NavLink>
            </li>
          </ul>
          <div className="tab-content">
            <div className="tab-pane active" id="setting">
              <div className="slim_scroll">
                <div className="card">
                  <h6>Choose Theme</h6>
                  <ul className="choose-skin list-unstyled mb-0">
                    <li data-theme="purple">
                      <div className="purple"></div>
                    </li>
                    <li data-theme="green">
                      <div className="green"></div>
                    </li>
                    <li data-theme="orange" className="active">
                      <div className="orange"></div>
                    </li>
                    <li data-theme="blue">
                      <div className="blue"></div>
                    </li>
                    <li data-theme="blush">
                      <div className="blush"></div>
                    </li>
                    <li data-theme="cyan">
                      <div className="cyan"></div>
                    </li>
                  </ul>
                </div>
                <div className="card">
                  <h6>General Settings</h6>
                  <ul className="setting-list list-unstyled mb-0">
                    <li>
                      <label className="fancy-checkbox">
                        <input
                          type="checkbox"
                          name="checkbox"
                          onChange={handleClick}
                        />
                        <span>Report Panel Usag</span>
                      </label>
                    </li>
                    <li>
                      <label className="fancy-checkbox">
                        <input
                          type="checkbox"
                          name="checkbox"
                          checked
                          onChange={handleClick}
                        />
                        <span>Email Redirect</span>
                      </label>
                    </li>
                    <li>
                      <label className="fancy-checkbox">
                        <input
                          type="checkbox"
                          name="checkbox"
                          checked
                          onChange={handleClick}
                        />
                        <span>Notifications</span>
                      </label>
                    </li>
                    <li>
                      <label className="fancy-checkbox">
                        <input
                          type="checkbox"
                          name="checkbox"
                          onChange={handleClick}
                        />
                        <span>Auto Updates</span>
                      </label>
                    </li>
                  </ul>
                </div>
                <div className="card">
                  <h6>Account Settings</h6>
                  <ul className="setting-list list-unstyled mb-0">
                    <li>
                      <label className="fancy-checkbox">
                        <input
                          type="checkbox"
                          name="checkbox"
                          onChange={handleClick}
                        />
                        <span>Offline</span>
                      </label>
                    </li>
                    <li>
                      <label className="fancy-checkbox">
                        <input
                          type="checkbox"
                          name="checkbox"
                          checked
                          onChange={handleClick}
                        />
                        <span>Location Permission</span>
                      </label>
                    </li>
                    <li>
                      <label className="fancy-checkbox">
                        <input
                          type="checkbox"
                          name="checkbox"
                          checked
                          onChange={handleClick}
                        />
                        <span>Notifications</span>
                      </label>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="tab-pane right_chat" id="chat">
              <div className="slim_scroll">
                <form>
                  <div className="input-group m-b-20">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="icon-magnifier"></i>
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search..."
                      onChange={handleClick}
                    />
                  </div>
                </form>
                <div className="card">
                  <h6>Recent</h6>
                  <ul className="right_chat list-unstyled mb-0">
                    <li className="online">
                      <NavLink to="#">
                        <div className="media">
                          <img
                            className="media-object "
                            src="../assets/images/xs/avatar4.jpg"
                            alt=""
                          />
                          <div className="media-body">
                            <span className="name">
                              Ava Alexander{" "}
                              <small className="float-right">Just now</small>
                            </span>
                            <span className="message">
                              Lorem ipsum Veniam aliquip culpa laboris minim
                              tempor
                            </span>
                            <span className="badge badge-outline status"></span>
                          </div>
                        </div>
                      </NavLink>
                    </li>
                    <li className="online">
                      <NavLink to="#">
                        <div className="media">
                          <img
                            className="media-object "
                            src="../assets/images/xs/avatar5.jpg"
                            alt=""
                          />
                          <div className="media-body">
                            <span className="name">
                              Debra Stewart{" "}
                              <small className="float-right">38min ago</small>
                            </span>
                            <span className="message">
                              Many desktop publishing packages and web page
                              editors
                            </span>
                            <span className="badge badge-outline status"></span>
                          </div>
                        </div>
                      </NavLink>
                    </li>
                    <li className="offline">
                      <NavLink to="#">
                        <div className="media">
                          <img
                            className="media-object "
                            src="../assets/images/xs/avatar2.jpg"
                            alt=""
                          />
                          <div className="media-body">
                            <span className="name">
                              Susie Willis{" "}
                              <small className="float-right">2hr ago</small>
                            </span>
                            <span className="message">
                              Contrary to belief, Lorem Ipsum is not simply
                              random text
                            </span>
                            <span className="badge badge-outline status"></span>
                          </div>
                        </div>
                      </NavLink>
                    </li>
                    <li className="offline">
                      <NavLink to="#">
                        <div className="media">
                          <img
                            className="media-object "
                            src="../assets/images/xs/avatar1.jpg"
                            alt=""
                          />
                          <div className="media-body">
                            <span className="name">
                              Folisise Chosielie{" "}
                              <small className="float-right">2hr ago</small>
                            </span>
                            <span className="message">
                              There are many of passages of available, but the
                              majority
                            </span>
                            <span className="badge badge-outline status"></span>
                          </div>
                        </div>
                      </NavLink>
                    </li>
                    <li className="online">
                      <NavLink to="#">
                        <div className="media">
                          <img
                            className="media-object "
                            src="../assets/images/xs/avatar3.jpg"
                            alt=""
                          />
                          <div className="media-body">
                            <span className="name">
                              Marshall Nichols{" "}
                              <small className="float-right">1day ago</small>
                            </span>
                            <span className="message">
                              It is a long fact that a reader will be distracted
                            </span>
                            <span className="badge badge-outline status"></span>
                          </div>
                        </div>
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="left-sidebar" className="sidebar">
          <div className="navbar-brand">
            {canAccessHome && (
              <NavLink to="/home">
                <img
                  src="../assets/images/logos/logo_31jours_2.png"
                  alt="31 jours Logo"
                  className="img-fluid logo"
                />
                <span>P31 jours</span>
              </NavLink>
            )}
            <button
              type="button"
              className="btn-toggle-offcanvas btn btn-sm btn-default float-right"
            >
              <i className="lnr lnr-menu fa fa-chevron-circle-left"></i>
            </button>
          </div>
          <div className="sidebar-scroll">
            <div className="user-account">
              <div className="user_div">
                <img
                  src={"../assets/images/logos/avatar-man.png"}
                  className={"user-photo"}
                  alt={"User Profile"}
                />
              </div>
              <div className="dropdown">
                <span>Bienvenue,</span>
                <NavLink
                  to="/home"
                  className="dropdown-toggle user-name"
                  data-toggle="dropdown"
                >
                  <strong>{session?.fullname}</strong>
                </NavLink>
                <ul className="dropdown-menu dropdown-menu-right account">
                  <li>
                    <NavLink to="/home">
                      <i className="icon-user"></i> Profile
                    </NavLink>
                  </li>
                  <li className="divider"></li>
                  <li>
                    <Button variant="danger" onClick={handleShow}>
                      <i className="icon-power"> Déconnexion</i>
                    </Button>
                    <Modal show={show} onHide={handleClose}>
                      <Modal.Header closeButton>
                        <Modal.Title>Déconnexion</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        Vous pouvez vous déconnecter en toute sécurité!
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                          Fermer
                        </Button>
                        <Button variant="danger" onClick={handleLogout}>
                          Déconnexion
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </li>
                </ul>
              </div>
            </div>
            <nav id="left-sidebar-nav" className="sidebar-nav">
              <ul id="main-menu" className="metismenu">
                <li className="tab">
                  <NavLink to="/home">
                    <i className="icon-home"></i>
                    <span>Tableau de bord</span>
                  </NavLink>
                </li>
                {canAccessGestionNc && (
                  <li>
                    <a href="#forms" className="has-arrow">
                      <i className="fa fa-address-card"></i>
                      <span>Gestion des NC</span>
                    </a>
                    <ul>
                      {canAccessNcPrincipale && (
                        <li>
                          <NavLink to="/nc-liste-principale">
                            <span>Liste principale</span>
                          </NavLink>
                        </li>
                      )}
                      {canAccessNcPerseverance && (
                        <li>
                          <NavLink to="/nc-liste-perseverance">
                            <span>Liste perséverance</span>
                          </NavLink>
                        </li>
                      )}
                      {canAccessAttribution && (
                        <NavLink to="/nc-liste-attente">
                          <span>Liste en attente</span>
                        </NavLink>
                      )}
                      {canWriteMesSaisies && (
                        <li>
                          <NavLink to="/nc-mes-saisies">
                            <span>Mes saisies</span>
                          </NavLink>
                        </li>
                      )}
                      {canAccessPointage && (
                        <li>
                          <NavLink to="/pointage-bilans">
                            <span>Pointage</span>
                          </NavLink>
                        </li>
                      )}
                    </ul>
                  </li>
                )}
                {canAccessSuiviTelephonique && (
                  <li>
                    <a href="#forms" className="has-arrow">
                      <i className="fa fa-phone-square"></i>
                      <span>Suivi Téléphonique</span>
                    </a>
                    <ul>
                      {canAccessMaListeAppel && (
                        <li>
                          <NavLink to="/ma-liste-appel">
                            <span>Ma liste d'appels</span>
                          </NavLink>
                        </li>
                      )}
                      {canAccessMonSuivi && (
                        <li>
                          <NavLink to="/mon-suivi">
                            <span>Mes comptes rendus</span>
                          </NavLink>
                        </li>
                      )}
                      {canAccessSuiviPerseverance && (
                        <li>
                          <NavLink to="/suivi-perseverance">
                            <span>Suivi persévérance</span>
                          </NavLink>
                        </li>
                      )}
                      {canAccessSuiviPrincipale && (
                        <li>
                          <NavLink to="/suivi-principale">
                            <span>Suivi principal</span>
                          </NavLink>
                        </li>
                      )}
                      {canAccessAttribution && (
                        <NavLink to="/suivi-action">
                          <span>Suivi pour action</span>
                        </NavLink>
                      )}
                    </ul>
                  </li>
                )}
                {canAccessAttribution && (
                  <li>
                    <a href="#forms" className="has-arrow">
                      <i className="fa fa-user-group"></i>
                      <span>Attributions</span>
                    </a>
                    <ul>
                    <li>
                        <NavLink to="/attribution-list">
                          <span>Liste des attributions</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/attribution-coach">
                          <span>Attribution Coach</span>
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                )}
                {canAccessCertificat && (
                  <li>
                    <a href="#forms" className="has-arrow">
                      <i className="fa fa-certificate"></i>
                      <span>Gestion des certificats</span>
                    </a>
                    <ul>
                      <li>
                        <NavLink to="/certificats-reussite">
                          <span>certificats</span>
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                )}
                <li>
                  <a href="#forms" className="has-arrow">
                    <i className="fa fa-comments"></i>
                    <span>Messagerie</span>
                  </a>
                  <ul>
                    <li>
                      <NavLink to="/messagerie">
                        <span>Boite de réception</span>
                      </NavLink>
                    </li>
                  </ul>
                </li>
                {canAccessFormations && (
                  <li>
                    <a href="#forms" className="has-arrow">
                      <i className="fa fa-graduation-cap"></i>
                      <span>Formation </span>
                    </a>
                    <ul>
                      <li>
                        <NavLink to="/formations">
                          <span>formations</span>
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                )}
                {AuthorizationService.writeAdmin() ? (
                  <li>
                    <a href="#forms" className="has-arrow">
                      <i className="fa fa-unlock-alt"></i>
                      <span>Administration</span>
                    </a>
                    <ul>
                      <li>
                        <NavLink to="/list-users">
                          <span>Membres</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/list-profils">
                          <span>Profils</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/imports">
                          <span>Import de fichiers</span>
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                ) : null}
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Nav;
