import React from "react";
import { Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import { DataGrid, GridToolbar, frFR } from "@mui/x-data-grid";
import { useDemoData } from "@mui/x-data-grid-generator";
import EditCompteRendu from "../../pages/appel/edit-appel";

import SuiviTelephoniqueService from "../../services/suivi-telephonique-service";
import AuthorizationService from "../../services/authorization-service";

const VISIBLE_FIELDS = [
  "id",
  "lastname",
  "firstname",
  "email",
  "telephone",
  "genre",
  "est_mineur",
  "birthdate",
  "metier",
];
const SuiviTelphoniqueTable = ({ contacts, statuts, sendUpdate }) => {
  const columnVisibilityModel = {
    id: false,
    contact_id: false,
    coach_id: false,
    created_at: false,
    subscription_date: false,
    name: false,
    firstname: false,
    email: false,
    contact:false
  };

  const [pageSize, setPageSize] = React.useState(5);
  const columns = [
    {
      field: "id",
      headerClassName: "header-prog31",
      headerName: "Id",
      flex: 1,
    },
    {
      field: "name",
      headerClassName: "header-prog31",
      headerName: "Nom",
      flex: 1,
    },
    {
      field: "firstname",
      headerClassName: "header-prog31",
      headerName: "Prenom",
      flex: 1,
    },
    {
      field: "coach_name",
      headerClassName: "header-prog31",
      headerName: "Coach",
      flex: 1,
    },
    {
      field: "email",
      headerClassName: "header-prog31",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "contact_name",
      headerClassName: "header-prog31",
      headerName: "Contact",
      flex: 1,
    },
    {
      field: "date_appel",
      headerClassName: "header-prog31",
      headerName: "Date d'appel",
      flex: 1,
    },
    {
      field: "statut_appel",
      headerClassName: "header-prog31",
      headerName: "Statut d'appel",
      flex: 1,
    },
    {
      field: "commentaires",
      type: "",
      headerClassName: "header-prog31",
      headerName: "Commentaires",
      flex: 1,
    },
    {
      field: "subscription_date",
      headerClassName: "header-prog31",
      headerName: "Date de saisie",
      flex: 1,
    },
    {
      field: "created_at",
      headerClassName: "header-prog31",
      headerName: "Créé le",
      flex: 1,
    },
    {
      field: "action",
      headerName: "Actions",
      headerClassName: "header-prog31",
      flex: 1,

      // Important: passing id from customers state so I can delete or edit each user
      renderCell: (suivi) => (
        <React.Fragment>
          <EditCompteRendu
            nc={suivi.row}
            id={suivi}
            statuts={statuts}
            sendUpdate={sendUpdate}
          />
          {(AuthorizationService.writeSuiviPerseverance() ||
            AuthorizationService.writeSuiviPrincipale()) && (
            <Button
              id="deleteBtn"
              style={{
                backgroundColor: "#e8605d",
                color: "#fff",
                padding: "6px 2px",
              }}
              onClick={() => {
                if (
                  window.confirm(
                    "Êtes-vous sûr de vouloir supprimer cet enregistrement ?"
                  )
                ) {
                  handleDelete(suivi);
                }
              }}
              variant="contained"
              color="primary"
              type="submit"
            >
              <DeleteIcon />
            </Button>
          )}
        </React.Fragment>
      ),
    },
  ];

  const { data } = useDemoData({
    dataSet: "Commodity",
    rowLength: 100,
    maxColumns: 6,
    visibleFields: VISIBLE_FIELDS,
  });

  const handleDelete = (user) => {
    SuiviTelephoniqueService.remove(user.id)
      .then((data) => {
        toast.success("Suppression réussie!");
        sendUpdate(true);
      })
      .catch((e) => {
        console.log(e);
        toast.error("Problème observé lors de la suppression");
      });
  };

  return (
    <div style={{ height: 450, width: "100%" }}>
      <DataGrid
        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
        {...data}
        initialState={{
          sorting: {
            sortModel: [{ field: "id", sort: "desc" }],
          },
        }}
        components={{ Toolbar: GridToolbar }}
        rows={contacts}
        columns={columns}
        columnVisibilityModel={columnVisibilityModel}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[5, 10, 20]}
        pagination
        checkboxSelection
        rowHeight={40}
      />
    </div>
  );
};

export default SuiviTelphoniqueTable;
