import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Button } from "@mui/material";
import AffecService from "../../services/suivi-contacts-service";
import AuthorizationService from "../../services/authorization-service";
import { Edit } from "@mui/icons-material";
import { toast, ToastContainer } from "react-toastify";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Col, Row } from "react-bootstrap";

const EditAffectation = (props) => {
  const [contacts, setContacts] = useState([]);
  const [users, setUsers] = useState([]);
  const [affectation, setAffectation] = useState([]);
  const [show, setShow] = useState(false);

  useEffect(() => {
    setAffectation(props.affectation);
    setContacts(props.contacts);
    setUsers(props.users);
  }, [props.affectation, props.contacts, props.users]);

  const handleClose = () => {
    setShow(false);
    props.sendUpdate(true);
  };
  const handleShow = () => setShow(true);

  const handleCommentaire = (e) => {
    setAffectation({ ...affectation, commentaires: e.target.value });
  };
  const handleDateFin = (e) => {
    setAffectation({ ...affectation, date_fin: e.target.value });
  };
  const handleDateDebut = (e) => {
    setAffectation({ ...affectation, date_debut: e.target.value });
  };

  const handleDoTermine = (e) => {
    setAffectation({ ...affectation, do_termine: e.target.checked });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    affectation.is_termine = affectation.do_termine;
    AffecService.update(affectation.id, affectation)
      .then((data) => {
        toast.success("Suivi modifié avec succès !", { autoClose: 2000 });
        props.sendUpdate(true);
      })
      .catch((e) => {
        toast.error(e.response.data.message, { autoClose: 2000 });
      });
    return;
  };

  return (
    <React.Fragment>
      <Button
        variant="contained"
        color="warning"
        onClick={handleShow}
        className="m-1 text-white"
      >
        <Edit />
      </Button>

      <Modal show={show} onHide={handleClose}>
        <AvForm onValidSubmit={handleSubmit}>
          <Modal.Header>
            <Modal.Title className="text-31-secondary">
              <small>Modifier une affectation</small>
            </Modal.Title>
            <button
              onClick={handleClose}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <Row className="mb-3">
              <Col lg="1">&nbsp;</Col>
              <Col lg="10">
                {AuthorizationService.writeAttribution() && (
                  <React.Fragment>
                    <input
                      className="form-check-input"
                      name="do_termine"
                      type="checkbox"
                      defaultChecked={false}
                      onChange={handleDoTermine}
                      id="do_termine"
                      disabled={affectation.is_termine}
                    />
                    <label className="form-check-label" htmlFor="do_termine">
                      Mettre fin au suivi
                    </label>
                  </React.Fragment>
                )}
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <AvField
                  type="select"
                  id="coach_id"
                  className="form-control"
                  disabled={true}
                  value={affectation.coach_id}
                  label="Coach"
                  name="coach_id"
                  onChange={(e) => console.log(e.target.value)}
                >
                  {users.map((user) => (
                    <option key={user.id} value={user.id}>
                      {user.coach}
                    </option>
                  ))}
                </AvField>
              </Col>
              <Col lg="6">
                <AvField
                  type="select"
                  id="contact_id"
                  className="form-control"
                  name="contact_id"
                  value={affectation.contact_id}
                  label="Contact"
                  disabled={true}
                  onChange={(e) => console.log(e.target.value)}
                >
                  {contacts.map((contact) => (
                    <option key={contact.id} value={contact.id}>
                      {contact.fullname}
                    </option>
                  ))}
                </AvField>
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <AvField
                  type="date"
                  className="form-control"
                  placeholder="Date de début"
                  id="date_debut"
                  disabled={true}
                  name="date_debut"
                  value={affectation.date_debut}
                  onChange={handleDateDebut}
                  label="Date de Début"
                  errorMessage="Date de début obligatoire !"
                  validate={{ required: { value: true } }}
                />
              </Col>
              <Col lg="6">
                <AvField
                  type="date"
                  className="form-control"
                  id="date_fin"
                  disabled={!affectation.do_termine || affectation.is_termine}
                  name="date_fin"
                  value={affectation.date_fin}
                  onChange={handleDateFin}
                  label="Date de fin de suivi"
                  errorMessage="Date de fin de suivi obligatoire !"
                  validate={{ required: { value: affectation.do_termine } }}
                />
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <AvField
                  type="textarea"
                  className="form-control"
                  id="commentaires"
                  disabled={!affectation.do_termine || affectation.is_termine}
                  rows="3"
                  name="commentaires"
                  onChange={handleCommentaire}
                  label="Commentaires"
                  value={affectation.commentaires || ""}
                  errorMessage="Commentaires obligatoires !"
                  validate={{ required: { value: affectation.do_termine } }}
                ></AvField>
              </Col>
            </Row>
            <ToastContainer autoClose={3000} />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="outlined"
              color="primary"
              type="submit"
              disabled={!affectation.do_termine || affectation.is_termine}
            >
              Modifier
            </Button>
            <Button
              variant="outlined"
              color="error"
              className="ml-2"
              onClick={handleClose}
            >
              Fermer
            </Button>
          </Modal.Footer>
        </AvForm>
      </Modal>
    </React.Fragment>
  );
};

export default EditAffectation;
