import React from "react";
import { Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { DataGrid, GridToolbar, frFR } from "@mui/x-data-grid";
import { useDemoData } from "@mui/x-data-grid-generator";
import ContactService from "../../services/contact-service";
import DialogModificationNc from "../../pages/gestion-nc/dialog-modification-nc";
import { toast } from "react-toastify";
import AuthorizationService from "../../services/authorization-service";

const VISIBLE_FIELDS = [
  "id",
  "lastname",
  "firstname",
  "email",
  "telephone",
  "genre",
  "est_mineur",
  "birthdate",
  "metier",
];
const NcSaisiesTable = ({ contacts, sendUpdate }) => {
  const [pageSize, setPageSize] = React.useState(5);

  const columnVisibilityModel = {
    id: false,
    subscription_date: false,
    genre: false,
    author: false,
    birthdate: false,
    metier: false,
    ville: false,
    code_postal: false,
    secteur_gf: false,
    adresse_postale: false,
  };

  const columns = [
    {
      field: "id",
      headerName: "Id",
      flex: 0,
      width: 40,
      headerClassName: "header-prog31",
    },
    {
      field: "lastname",
      headerName: "Nom",
      flex: 1,
      headerClassName: "header-prog31",
    },
    {
      field: "firstname",
      headerName: "Prenom",
      flex: 1,
      headerClassName: "header-prog31",
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      headerClassName: "header-prog31",
    },
    {
      field: "telephone",
      headerName: "Telephone",
      flex: 0,
      width: 100,
      headerClassName: "header-prog31",
    },
    {
      field: "genre",
      headerName: "Genre",
      hide: true,
      headerClassName: "header-prog31",
    },
    {
      field: "etat_mineur",
      headerName: "Ado/Adulte",
      flex: 0,
      width: 110,
      headerClassName: "header-prog31",
    },
    {
      field: "birthdate",
      hide: false,
      headerName: "Né le",
      flex: 0,
      headerClassName: "header-prog31",
      width: 120,
    },
    {
      field: "subscription_date",
      hide: false,
      headerName: "Date de saisie",
      flex: 0,
      width: 120,
      headerClassName: "header-prog31",
    },
    {
      field: "libelle_source",
      type: "",
      headerName: "Source",
      flex: 1,
      headerClassName: "header-prog31",
      headerAlign: "center",
    },
    {
      field: "metier",
      type: "",
      headerName: "Métier",
      flex: 1,
      headerClassName: "header-prog31",
    },
    {
      field: "action",
      headerName: "Actions",
      flex: 0,
      width: 150,
      headerClassName: "header-prog31",

      renderCell: (id) => (
        <div>
          <DialogModificationNc nc={id.row} id={id} sendUpdate={sendUpdate} />
          {AuthorizationService.writeMesSaisies() && (
            <Button
              id="deleteBtn"
              style={{
                backgroundColor: "#e8605d",
                color: "#fff",
                padding: "7px 7px",
              }}
              onClick={() => {
                if (
                  window.confirm(
                    "Êtes-vous sûr de vouloir supprimer cet enregistrement ?"
                  )
                ) {
                  handleDelete(id);
                }
              }}
              variant="contained"
              color="primary"
              type="submit"
            >
              <DeleteIcon />
            </Button>
          )}
        </div>
      ),
    },
  ];

  const { data } = useDemoData({
    dataSet: "Commodity",
    rowLength: 100,
    maxColumns: 6,
    visibleFields: VISIBLE_FIELDS,
  });
  const handleDelete = (contact) => {
    ContactService.remove(contact.id)
      .then((data) => {
        toast.success("Contact supprimé !", { autoClose: 2000 });
        sendUpdate(true);
      })
      .catch((e) => {
        toast.error(e.response.data.description, {
          autoClose: 3000,
          onClose: () => {}, // TODO, mise à jour des écrans
        });
      });
  };
  return (
    <div style={{ height: 450, width: "100%" }}>
      <DataGrid
        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
        {...data}
        initialState={{
          sorting: {
            sortModel: [{ field: "id", sort: "desc" }],
          },
        }}
        components={{ Toolbar: GridToolbar }}
        rows={contacts}
        columns={columns}
        columnVisibilityModel={columnVisibilityModel}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[5, 10, 20]}
        pagination
        checkboxSelection
        rowHeight={40}
        columnHeaderHeight={40}
      />
    </div>
  );
};

export default NcSaisiesTable;
