import React, { useState } from "react";
import { useStore } from "react-redux";
import { toast } from "react-toastify";
import { Button } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import AdresseForm from "./adresse-form";
import Modal from "react-bootstrap/Modal";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Edit, Person } from "@mui/icons-material";
import ContactService from "../../services/contact-service";
import AuthorizationService from "../../services/authorization-service";
import moment from "moment";

const DialogModificationNc = (props) => {
  const initialState = {
    firstname: props.nc.firstname,
    lastname: props.nc.lastname,
    telephone: props.nc.telephone,
    email: props.nc.email,
    birthdate: props.nc?.birthdate,
    est_mineur: props.nc?.est_mineur ?? false,
    genre: props.nc?.genre,
    civilite: props.nc?.civilite,
    metier: props.nc.metier,
    liste_id: props.nc.liste_id ?? props.nc.liste.id,
    liste_libelle: props.nc.liste?.libelle,
    liste: props.nc?.liste,
    source_id: props.nc.source_id ?? props.nc.source.id,
    source_libelle: props.nc.source?.libelle,
    source: props.nc?.source,
    secteur_gf: props.nc?.secteur_gf,
    ville: props.nc?.ville,
    code_postal: props.nc.code_postal,
    adresse_postale: props.nc.adresse_postale,
    subscription_date: moment(props.nc.subscription_date).format("yyyy-MM-DD"),
    is_livre: props.nc.is_livre,
  };

  const store = useStore();
  const [nc, setNc] = useState(initialState);
  const [listes] = useState(store.getState().referentiel.listes);
  const [sources] = useState(store.getState().referentiel.sources);
  const [secteurs] = useState(store.getState().referentiel.secteurs);
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const handleChange = (event) => {
    event.preventDefault();
    const name = event.target.name;
    const value = event.target.value;
    let data = { ...nc, [name]: value };
    setNc(data);
    console.log(data);
  };

  const handleLivre = (e) => {
    setNc({ ...nc, is_livre: e.target.checked });
  };

  const handleUpdateSubmit = (e) => {
    e.preventDefault();
    const putContacts = { ...nc };
    putContacts.subscription_date = moment(nc.subscription_date).format(
      "yyyy-MM-DD"
    );
    putContacts.birthdate = moment(nc.birthdate).format("yyyy-MM-DD");
    ContactService.update(props.nc.id, putContacts)
      .then((data) => {
        toast.success("Modification réussie!", { autoClose: 2000 });
        props.sendUpdate(true);
      })
      .catch((e) => {
        toast.error(e.response.data.message, { autoClose: 2000 });
      });
  };
  return (
    <React.Fragment>
      <Button
        variant="contained"
        color="warning"
        onClick={handleShow}
        className="m-1 text-white"
      >
        {props.icon === "suivi" ? <Person /> : <Edit />}
      </Button>
      <Modal size="lg" show={show} onHide={handleClose}>
        <AvForm onValidSubmit={handleUpdateSubmit}>
          <Modal.Header>
            <Modal.Title className="text-31-secondary">
              <small>Modifier un NC</small>
            </Modal.Title>
            <button
              onClick={handleClose}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col lg="3">
                <AvField
                  type="select"
                  id="civilite"
                  name="civilite"
                  value={nc.civilite}
                  onChange={handleChange}
                  className="form-control"
                  label="Civilité"
                >
                  <option hidden>{nc.civilite}</option>
                  <option value="Madame">Madame</option>
                  <option value="Mademoiselle">Mademoiselle</option>
                  <option value="Monsieur">Monsieur</option>
                </AvField>
              </Col>
              <Col lg="4">
                <AvField
                  type="text"
                  className="form-control"
                  placeholder="Saisir le nom"
                  id="lastname"
                  label="Nom"
                  name="lastname"
                  value={nc.lastname}
                  onChange={handleChange}
                  errorMessage="Nom obligatoire !"
                  validate={{ required: { value: true } }}
                />
              </Col>
              <Col lg="4">
                <AvField
                  type="text"
                  className="form-control"
                  placeholder="Entrer le prénom"
                  label="Prénom"
                  id="prenom"
                  name="firstname"
                  value={nc.firstname}
                  onChange={handleChange}
                  errorMessage="Prénom obligatoire !"
                  validate={{ required: { value: true } }}
                />
              </Col>
            </Row>
            <Row>
              <Col lg="3">
                <label htmlFor="est_mineur">Ado/Adulte:</label>
                <select
                  className="form-control"
                  id="est_mineur"
                  name="est_mineur"
                  value={nc.est_mineur}
                  onChange={handleChange}
                >
                  <option hidden></option>
                  <option value="1">Adolescent</option>
                  <option value="0">Adulte</option>
                </select>
              </Col>
              <Col lg="4">
                <AvField
                  type="date"
                  className="form-control"
                  id="birthdate"
                  name="birthdate"
                  label="Date de naissance"
                  value={nc.birthdate}
                  onChange={handleChange}
                  errorMessage="Date de naissance bligatoire !"
                  validate={{ required: { value: true } }}
                />
              </Col>
              <Col lg="4">
                <AvField
                  type="text"
                  className="form-control"
                  placeholder="Saisir le métier"
                  id="metier"
                  name="metier"
                  value={nc.metier}
                  onChange={handleChange}
                  label="Métier"
                />
              </Col>
            </Row>
            <Row>
              <Col lg="4">
                <AvField
                  type="email"
                  className="form-control"
                  placeholder="Saisir l'email"
                  label="Email"
                  id="email"
                  name="email"
                  value={nc.email}
                  onChange={handleChange}
                />
              </Col>
              <Col lg="4">
                <AvField
                  type="text"
                  className="form-control"
                  placeholder="Saisir le téléphone"
                  label="Téléphone"
                  id="telephone"
                  name="telephone"
                  value={nc.telephone}
                  onChange={handleChange}
                  errorMessage="N° de télphone obligatoire !"
                  validate={{ required: { value: true } }}
                />
              </Col>
            </Row>
            <Row>
              <Col lg="4">
                <AvField
                  type="text"
                  className="form-control"
                  id="adresse_postale"
                  name="adresse_postale"
                  placeholder="Adresse postale..."
                  value={nc.adresse_postale}
                  onChange={handleChange}
                  label="Adresse postale"
                />
              </Col>
              <Col lg="3">
                <AdresseForm
                  setCodePostal={(codePostal, ville) =>
                    setNc({ ...nc, code_postal: codePostal, ville: ville })
                  }
                  value={nc.ville}
                />
              </Col>
              <Col lg="3">
                <AvField
                  type="text"
                  className="form-control"
                  id="code_postal"
                  name="code_postal"
                  label="Code postal"
                  placeholder="Entre le code postal"
                  value={nc.code_postal}
                  onChange={handleChange}
                />
              </Col>
            </Row>
            <Row>
              <Col lg="4">
                <AvField
                  type="select"
                  className="form-control"
                  id="source_id"
                  name="source_id"
                  value={nc.source_id}
                  onChange={handleChange}
                  label="Source"
                  errorMessage="Source obligatoire !"
                  validate={{ required: { value: true } }}
                >
                  <option hidden>Choisir ...</option>
                  {sources.map((s) => (
                    <option key={s.id} value={s.id}>
                      {s.libelle}
                    </option>
                  ))}
                </AvField>
              </Col>
              <Col lg="4">
                <AvField
                  type="date"
                  className="form-control"
                  placeholder="Date d'enregistrement"
                  id="subscription_date"
                  name="subscription_date"
                  value={nc.subscription_date}
                  onChange={handleChange}
                  label="Date d'enregistrement"
                  errorMessage="Date d'inscription obligatoire !"
                  validate={{ required: { value: true } }}
                />
              </Col>
              <Col lg="1">&nbsp;</Col>
              <Col lg="3">
                <input
                  className="form-check-input"
                  name="is_livre"
                  type="checkbox"
                  defaultChecked={nc.is_livre}
                  onChange={handleLivre}
                  id="is_livre"
                />
                <label className="form-check-label" htmlFor="is_livre">
                  Livre 31 Jours
                </label>
              </Col>
            </Row>
            <Row>
              <Col lg="4">
                <AvField
                  type="select"
                  className="form-control"
                  id="secteur_gf"
                  name="secteur_gf"
                  value={nc.secteur_gf}
                  onChange={handleChange}
                  label="Secteur GF"
                >
                  <option value="">Choisir un secteur</option>
                  {Array.isArray(secteurs) &&
                    secteurs.map((s, index) => (
                      <option key={index} value={s}>
                        {s}
                      </option>
                    ))}
                </AvField>
              </Col>
              {AuthorizationService.writeAttribution() && (
              <Col lg="4">
                <AvField
                  type="select"
                  className="form-control"
                  id="liste_id"
                  name="liste_id"
                  value={nc.liste_id}
                  onChange={handleChange}
                  label="Liste"
                >
                  <option hidden>Choisir ...</option>
                  {Array.isArray(listes) &&
                    listes.map((l) => (
                      <option key={l.id} value={l.id}>
                        {l.libelle}
                      </option>
                    ))}
                </AvField>
              </Col>
              )}
            </Row>
          </Modal.Body>
          <Modal.Footer>
            {(AuthorizationService.writeListePrincipale() ||
              AuthorizationService.writeListePerseverance() ||
              AuthorizationService.writeMesSaisies()) && (
              <Button
                variant="contained"
                color="primary"
                type="submit"
                className="ml-1 mr-1"
              >
                Modifier
              </Button>
            )}
            <Button
              variant="contained"
              color="error"
              onClick={handleClose}
              className="ml-1 mr-1"
            >
              Fermer
            </Button>
          </Modal.Footer>
        </AvForm>
      </Modal>
    </React.Fragment>
  );
};

export default DialogModificationNc;
