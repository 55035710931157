import React, { useState, useRef, useEffect } from "react";
import { useStore } from "react-redux";

import ContactService from "../../services/contact-service";
import BilanService from "../../services/bilan-service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Col, Row } from "react-bootstrap";


const Pointage = () => {
  const [key, setKey] = useState("");
  const [contacts, setContacts] = useState([]);
  const [data, setData] = useState([]);
  const [msg, setMsg] = useState("");
  const bilanId = useRef();
  const dateBilan = useRef();

  const store = useStore();
  const [layout, setLayout] = useState(store.getState().layout);
  const [bilans] = useState(store.getState().referentiel.bilans);

  const handleSearchClick = (e) => {
    e.preventDefault();
    setContacts([]);
    ContactService.search(key)
      .then((response) => {
        if (response.data.data.length > 0) {
          setContacts(response.data.data);
        } else {
          setMsg("Pas de NC trouvé");
        }
      })
      .catch((err) => setMsg("Une erreur non gérée a été détectée"));
  };

  const handleChange = (contact, isChecked) => {
    if (isChecked) {
      contact.status = "En attente";
      setData((prevData) => [...prevData, contact]);
    } else {
      setData((prevData) => prevData.filter((c) => c.id !== contact.id));
    }
  };

  const handlePointage = (e) => {
    e.preventDefault();
    if (parseInt(bilanId.current.value) === 0) {
      toast.error("Vous devez selectionner une semaine !");
      return;
    }
    data.forEach((d) => {
      console.log(bilanId.current.value);
      BilanService.add({
        contact_id: parseInt(d.id),
        bilan_id: parseInt(bilanId.current.value),
        date: dateBilan.current.valueAsDate.toISOString().slice(0, 10),
      })
        .then((response) => {
          d.status = "Succès";
          d.description = response.data.description;
          setData((prevData) => [...prevData.filter((c) => c.id !== d.id), d]);
        })
        .catch((error) => {
          d.status = "Echec";
          d.description = error.response.data.description;
          setData((prevData) => [...prevData.filter((c) => c.id !== d.id), d]);
        });
    });
  };

  useEffect(() => {
    store.subscribe(() => {
      setLayout(store.getState().layout);
    });
  }, [store]);

  return (
    <div id="main-content" className={layout?.isFull ? "layout-fullwidth" : ""}>
      <div className="block-header  sticky-header">
        <div className="row clearfix">
          <div className="col-md-6 col-sm-12">
            <h4 className="text-31-secondary">Pointage bilan des NC</h4>
          </div>
          <div className="col-md-6 col-sm-12 text-right">
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="index.html">
                  <i className="icon-home"></i>
                </a>
              </li>
              <li className="breadcrumb-item">Gestion des NC</li>
              <li className="breadcrumb-item active">Pointage</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row clearfix">
          <Col lg={12}>
            <div className="card">
              <div className="body">
                <Row>
                  <Col lg={4}>
                    <label htmlFor="searchKey">Recherche de NC</label>
                    <div className="input-group">
                      <input
                        id="searchKey" name="searchKey"
                        type="search"
                        className="form-control"
                        placeholder="Rechercher ..."
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        onChange={(e) => { setKey(e.target.value); }}
                      />
                      <button className="btn btn-secondary" onClick={handleSearchClick} >
                        <span
                          className=" fa fa-search"
                          id="basic-addon1"
                        ></span>
                      </button>
                    </div>
                  </Col>
                  <Col lg={2}>&nbsp;</Col>
                  <Col lg="3">
                    <label htmlFor="num_bilan">N° de Bilan</label>
                    <select className="form-control" name="num_bilan" id="num_bilan" ref={bilanId} placeholder="N° Semaine...">
                    <option value="0">Choisir semaine</option>
                      {bilans.map((item) => (
                        <option value={item.id} key={item.id}>
                          {item.nom}
                        </option>
                      ))}
                    </select>
                  </Col>
                  <div className="col-3">
                    <label htmlFor="date_nc">Date de Session</label>
                    <input
                      type="date"
                      className="form-control"
                      name="date_nc"
                      id="date_nc"
                      ref={dateBilan}
                      value={new Date().toISOString().split("T")[0]}
                      max={new Date().toISOString().split("T")[0]}
                    />
                  </div>
                </Row>
                <Row className="mt-3">
                  <Col lg={6}>
                    {contacts && contacts.length !== 0 ? (
                      <div>
                        <span className="text-31-primary">
                          <strong>
                            Résultat(s) à votre recherche:{" "}
                            {contacts.length} NCs
                          </strong>
                        </span>
                        <form>
                          <div className="table-responsive">
                            <table className="table m-b-0">
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th>Nom</th>
                                  <th>Prénom</th>
                                  <th>Téléphone</th>
                                </tr>
                              </thead>
                              <tbody>
                                {contacts.map((contact) => (
                                  <tr>
                                    <td><input
                                      type="checkbox"
                                      value={contact.id}
                                      onChange={(e) =>
                                        handleChange(
                                          contact,
                                          e.target.checked
                                        )
                                      }
                                    /></td>
                                    <td>{contact.firstname}</td>
                                    <td>{contact.lastname}</td>
                                    <td>{contact.telephone}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </form>
                      </div>
                    ) : (
                      <strong className="text-danger text-center">
                        {msg}
                      </strong>
                    )}
                  </Col>
                  <div className="col-6">
                    {data.length > 0 && (
                      <div className="row">
                        <div className="mt-2 col-12">
                          <span className="text-center text-31-primary">
                            <strong>NC sélectionnés pour le pointage</strong>
                          </span>
                          <table className="table table-hover table-striped">
                            <tbody>
                              <tr>
                                <th>Prénom</th>
                                <th>Nom</th>
                                <th>Téléphone</th>
                                <th>Status</th>
                                <th>Description</th>
                              </tr>
                              {data.map((contact) => (
                                <tr key={contact.id}>
                                  <td>{contact.firstname}</td>
                                  <td>{contact.lastname}</td>
                                  <td>{contact.telephone}</td>
                                  <td>{contact.status}</td>
                                  <td>{contact.description}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                        <div className="text-center my-3 col-12">
                          <button
                            mt="5"
                            type="button"
                            className="btn btn-outline-primary btn-lg"
                            data-bs-toggle="modal"
                            data-bs-target="#myModal"
                            onClick={handlePointage}
                          >
                            Procéder au pointage
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </Row>
                <ToastContainer />
              </div>
            </div>
          </Col>
        </div>
      </div>
    </div>
  );
};

export default Pointage;
