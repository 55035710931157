import React, { useState, useEffect } from "react";
import { useStore } from "react-redux";

import { ToastContainer } from "react-toastify";
import ContactService from "../../services/contact-service";
import NcAttenteTable from "../../components/layouts/data-table-attente";
import moment from "moment";

const ListNcAttente = () => {
  const initialState = [];
  const [contacts, setContacts] = useState(initialState);
  const [doUpdate, setDoUpdate] = useState(true);

  const store = useStore();
  const [layout, setLayout] = useState(store.getState().layout);

  useEffect(() => {
    store.subscribe(() => {
      setLayout(store.getState().layout);
    });

    if (doUpdate) {
      ContactService.getListeAttente().then((datas) => {
        const items = datas.data
          .filter((a) => a !== null)
          .map((a) => ({
            ...{
              id: a.id,
              civilite: a.civilite,
              lastname: a.lastname ,
              firstname: a.firstname,
              email: a.email,
              telephone: a.telephone,
              author: a.author?.firstname + " " + a.author?.name,
              bilans: a.contact_session_bilans.map((b) => ({
                id: b.id,
                bilan_id: b.bilan_id,
                date: b.date,
              })),
              etat_mineur: a.est_mineur ? "Ado" : "Adulte",
              est_mineur: a.est_mineur,
              metier: a.metier,
              author_id: a.author_id,
              subscription_date: moment(a.subscription_date).format(
                "yyyy-MM-DD"
              ),
              genre: a.genre,
              birthdate: moment(a.birthdate).format("yyyy-MM-DD"),
              secteur_gf: a.secteur_gf,
              ville: a.ville,
              code_postal: a.code_postal,
              adresse_postale: a.adresse_postale,
              source: a.source,
              source_id: a.source.source_id,
              liste_id: a.liste.liste_id,
              liste: a.liste,
              libelle_source: a.source.libelle,
              libelle_liste: a.liste.libelle,
              is_livre: a.is_livre,
              suivi_contacts : a.suivi_contacts,
            },
          }));
        setContacts(items);
      });
      setDoUpdate(false);
    }
  }, [contacts, doUpdate, store]);

  const sendUpdate = (value) => {
    setDoUpdate(value);
  };

  return (
    <div id="main-content" className={layout?.isFull ? "layout-fullwidth" : ""}>
      <div className="block-header sticky-header">
        <div className="row clearfix">
          <div className="col-md-6 col-sm-12">
            <h4 className="text-31-secondary">Liste en attente</h4>
          </div>
          <div className="col-md-6 col-sm-12 text-right">
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/home">
                  <i className="icon-home"></i>
                </a>
              </li>
              <li className="breadcrumb-item active">Gestion des NC</li>
              <li className="breadcrumb-item active">Liste en attente</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row clearfix">
          <div className="col-lg-12">
            <div className="card">
              <div className="body">
                <div className="table-responsive">
                  <NcAttenteTable
                    contacts={contacts}
                    sendUpdate={sendUpdate}
                  />
                  <ToastContainer />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListNcAttente;
