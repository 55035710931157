import React, { useState, useEffect } from "react";
import { useStore } from "react-redux";

import { ToastContainer } from "react-toastify";
import NcSaisiesTable from "../../components/layouts/data-table-mes-saisies";
import ContactService from "../../services/contact-service";
import DialogAjoutNc from "./dialog-ajout-nc";
import moment from "moment";

const ListeMesSaisies = () => {
  const initialState = [];
  const [contacts, setContacts] = useState(initialState);
  const [doUpdate, setDoUpdate] = useState(true);

  const store = useStore();
  const [layout, setLayout] = useState(store.getState().layout);

  useEffect(() => {
    store.subscribe(() => {
      setLayout(store.getState().layout);
    });

    if (doUpdate) {
      ContactService.getMesSaisies().then((datas) => {
        const items = datas.data.map((a) => ({
          ...{
            id: a.id,
            civilite: a.civilite,
            lastname: a.lastname,
            firstname: a.firstname,
            email: a.email,
            telephone: a.telephone,
            etat_mineur: a.est_mineur === 1 ? "Adolescent" : "Adulte",
            est_mineur: a.est_mineur,
            metier: a.metier,
            author_id: a.author_id,
            created_at: moment(a.created_at).format("yyyy-MM-DD"),
            subscription_date: moment(a.subscription_date).format("yyyy-MM-DD"),
            genre: a.genre,
            birthdate: moment(a.birthdate).format("yyyy-MM-DD"),
            secteur_gf: a.secteur_gf,
            ville: a.ville,
            code_postal: a.code_postal,
            adresse_postale: a.adresse_postale,
            source: a.source,
            source_id: a?.source.source_id,
            liste_id: a.liste.liste_id,
            liste: a.liste,
            libelle_source: a.source.libelle,
            libelle_liste: a.liste.libelle,
          },
        }));
        setContacts(items);
        setDoUpdate(false);
      });
    }
  }, [doUpdate, store]);

  const sendUpdate = (value) => {
    setDoUpdate(value);
  };

  return (
    <div id="main-content" className={layout?.isFull ? "layout-fullwidth" : ""}>
      <div className="block-header sticky-header">
        <div className="row clearfix">
          <div className="col-md-6 col-sm-12">
            <h4 className="text-31-secondary">Mes saisies</h4>
          </div>
          <div className="col-md-6 col-sm-12 text-right">
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/home">
                  <i className="icon-home"></i>
                </a>
              </li>
              <li className="breadcrumb-item active">Gestion des NC</li>
              <li className="breadcrumb-item active">Mes Saisies</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row clearfix">
          <div className="col-lg-12">
            <div className="card">
              <div className="body">
                <div className="table-responsive">
                  <div className="text-right mb-2">
                    <DialogAjoutNc sendUpdate={sendUpdate} />
                  </div>
                  <NcSaisiesTable
                    contacts={contacts}
                    sendUpdate={sendUpdate}
                  />
                  <ToastContainer />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListeMesSaisies;
