const getRefreshToken = () => {
  const session = JSON.parse(localStorage.getItem("session"));
  return session?.refreshToken;
}

const getAccessToken = () => {
  const session = JSON.parse(localStorage.getItem("session"));
  return session?.accessToken;
}

const updateAccessToken = (token) => {
  let user = JSON.parse(localStorage.getItem("session"));
  user.accessToken = token;
  localStorage.setItem("session", JSON.stringify(user));
}


const TokenService = {
  getRefreshToken,
  getAccessToken,
  updateAccessToken,
};
export default TokenService;
