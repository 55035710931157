import React, { useState, useEffect } from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import BilanService from "../../services/bilan-service";
import { toast, ToastContainer } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";
import { Modal, Button } from "react-bootstrap";
import InfoIcon from "@mui/icons-material/Info";
import UpdateSession from "./update-bilan-session";
import { Col, Row } from "react-bootstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useStore } from "react-redux";
import AuthorizationService from "../../services/authorization-service";

const ListBilan = (props) => {
  const initialState = [];
  const initialBilan = {
    contact_id: props.cellValues.id,
    bilan_id: 0,
    date: "",
  };

  const [data, setData] = useState(initialState);
  const [add, setAdd] = useState(false);
  const [bilan, setBilan] = useState(initialBilan);
  const [show, setShow] = useState(false);

  const store = useStore();
  const [bilans] = useState(store.getState().referentiel.bilans);

  useEffect(() => {
    const { bilans } = props.cellValues.row;
    setData(bilans);
  }, [props.cellValues.row]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCheckChange = (event) => {
    setAdd(event.target.checked);
  };

  const handleChange = (event) => {
    event.preventDefault();
    const name = event.target.name;
    const value = event.target.value;
    let data = { ...bilan, [name]: value };
    setBilan(data);
  };

  const handleDelete = (a) => {
    BilanService.remove(a.id).then((res) => {
      toast.success("Bilan supprimé avec succès!", { autoClose: 2000 });
      props.sendUpdate(true);
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (bilan.date === "") {
      toast.error("Vous devez choisir une date d'inscription !");
      return;
    }
    if (bilan.bilan_id === 0) {
      toast.error("Vous devez choisir une session de bilan !");
      return;
    }
    BilanService.add(bilan)
      .then((data) => {
        toast.success("Insertion réussie!", {
          onClose: () => {},
        });
        props.sendUpdate(true);
      })
      .catch((err) => {
        toast.error(err.response.data.description);
      });
  };

  return (
    <>
      <Button variant="info" onClick={handleShow} className="text-white m-1">
        <InfoIcon />
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        id={`session${props.cellValues.id}`}
      >
        <Modal.Header>
          <Modal.Title className="text-31-secondary">
            Bilan de sessions
          </Modal.Title>
          {AuthorizationService.writePointage() && (
            <div className="text-right ml-4">
              <FormGroup className="text-right">
                <FormControlLabel
                  control={
                    <Switch
                      className="text-right"
                      color="secondary"
                      checked={add}
                      onChange={handleCheckChange}
                    />
                  }
                  label="Lister / Ajouter"
                />
              </FormGroup>
            </div>
          )}
          <button
            onClick={handleClose}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-10 offset-1">
              <h5>
                {props.cellValues.row.civilite?.charAt(0).toUpperCase() +
                  props.cellValues.row.civilite?.slice(1)}{" "}
                {props.cellValues.row.lastname} {props.cellValues.row.firstname}
              </h5>
            </div>
          </div>
          {add ? (
            <div>
              <AvForm onValidSubmit={handleSubmit} className="mt-2 mb-1">
                <Row>
                  <Col sm={{ span: 5, offset: 1 }}>
                    <AvField
                      type="select"
                      id="semaines"
                      name="bilan_id"
                      onChange={handleChange}
                      className="form-control"
                      label="N° de Bilan"
                      errorMessage="Semaine de bilan obligatoire !"
                      validate={{ required: { value: true } }}
                    >
                      <option hidden>Choisir</option>
                      {bilans.map((item) => (
                        <option value={item.id} key={item.id}>
                          {item.nom}
                        </option>
                      ))}
                    </AvField>
                  </Col>
                  <Col sm={{ span: 5 }}>
                    <AvField
                      type="date"
                      id="date"
                      onChange={handleChange}
                      name="date"
                      label="Date se session"
                      className="form-control"
                      max={new Date().toISOString().split("T")[0]}
                      errorMessage="Date de session obligatoire !"
                      validate={{ required: { value: true } }}
                    />
                  </Col>
                </Row>
                <Row className="mt-1">
                  <Col sm={{ span: 4, offset: 1 }}>
                    <button type="submit" className="btn btn-secondary col-12">
                      Ajouter
                    </button>
                  </Col>
                </Row>
              </AvForm>
              <ToastContainer />
            </div>
          ) : (
            <ul className="list-group list-group-flush">
              {data.map((a, index) => {
                return (
                  <li key={index} className="list-group-item">
                    <span style={{ marginRight: "20px" }}>
                      Semaine {a.bilan_id}: Effectué le:{" "}
                      {a.date.split("-").reverse().join("-")}
                    </span>
                    {AuthorizationService.writePointage() && (
                      <React.Fragment>
                        <UpdateSession
                          id_session={a.id}
                          a_bilan={a}
                          semaine={a.date.split("-").reverse().join("-")}
                          sendUpdate={props.sendUpdate}
                        />
                        <Button
                          variant="danger"
                          onClick={(e) => {
                            e.preventDefault();
                            if (
                              window.confirm(
                                "Êtes-vous sûr de vouloir supprimer cet enregistrement ?"
                              )
                            ) {
                              handleDelete(a);
                            }
                          }}
                        >
                          <DeleteIcon
                            color="white"
                            className="text-end ml-1 mr-1"
                          />
                        </Button>
                      </React.Fragment>
                    )}
                  </li>
                );
              })}
            </ul>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Fermer
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ListBilan;
