import React, { useState } from "react";
import { useStore } from "react-redux";
import { toast } from "react-toastify";
import AdresseForm from "./adresse-form";
import ContactService from "../../services/contact-service";
import { Button } from "@mui/material";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Modal from "react-bootstrap/Modal";
import { Col, Row } from "react-bootstrap";
import moment from "moment";

const DialogAjoutNc = (props) => {

  const initialState = {
    firstname: "",
    lastname: "",
    telephone: "",
    email: "",
    birthdate: "",
    est_mineur: 0,
    genre: "",
    secteur_gf: "",
    civilite: "",
    metier: "",
    liste_id: "",
    source_id: "",
    ville: "",
    code_postal: "",
    postalCode: "",
    adresse_postale: "",
    subscription_date: "",
    is_livre: false
  };

  const store = useStore();
  const [sources] = useState(store.getState().referentiel.sources);
  const [secteurs] = useState(store.getState().referentiel.secteurs);

  const [nc, setNc] = useState(initialState);
  const [showModal, setShowModal] = useState(false);

  const handleChange = (event) => {
    event.preventDefault();
    const name = event.target.name;
    const value = event.target.value;
    let data = { ...nc, [name]: value };
    setNc(data);
  };

  const handleLivre = (e) => {
    setNc({ ...nc, is_livre: e.target.checked });
  };
  
  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleModalShow = () => {
    setNc(initialState);
    setShowModal(true);
  };

  const handleReinitial = (e) => {
    e.preventDefault();
    setNc(initialState);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const newContacts = { ...nc };
    newContacts.birthdate = moment(nc.birthdate).format("yyyy-MM-DD");
    newContacts.subscription_date = moment(nc.subscription_date).format("yyyy-MM-DD"); 
    ContactService.add(newContacts)
      .then((data) => {
        toast.success("Nouveau NC enregistré avec succès !");
        props.sendUpdate(true);
      })
      .catch((e) => {
        toast.error(e.response.data.message);
      });
  };

  return (
    <React.Fragment>
      <Button variant="contained" color="primary" onClick={handleModalShow} className="m-1 text-white">
        + Nouveau
      </Button>
      <Modal size="lg" show={showModal} onHide={handleModalShow}>
        <AvForm onValidSubmit={handleSubmit}>
          <Modal.Header>
            <Modal.Title className="text-31-secondary"><small>Créer un nouveau NC</small></Modal.Title>
            <button onClick={handleModalClose} type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col lg="3">
                <AvField type="select" id="civilite" name="civilite" value={nc.civilite}
                  onChange={handleChange} className="form-control" label="Civilité">
                  <option hidden>Choisir la civilité</option>
                  <option value="madame">Madame</option>
                  <option value="mademoiselle">Mademoiselle</option>
                  <option value="monsieur">Monsieur</option>
                </AvField>
              </Col>
              <Col lg="4">
                <AvField type="text" className="form-control" placeholder="Saisir le nom" id="lastname"
                  label="Nom" name="lastname" value={nc.lastname} onChange={handleChange}
                  errorMessage="Nom obligatoire !" validate={{ required: { value: true } }}
                />
              </Col>
              <Col lg="4">
                <AvField
                  type="text" className="form-control" placeholder="Entrer le prénom"
                  label="Prénom" id="prenom" name="firstname" value={nc.firstname} onChange={handleChange}
                  errorMessage="Prénom obligatoire !" validate={{ required: { value: true } }}
                />
              </Col>
            </Row>
            <Row>
              <Col lg="3">
              <AvField type="select" className="form-control" id="est_mineur" name="est_mineur"
                  value={nc.est_mineur} onChange={handleChange} label="Adolescent / Adulte"
                >
                  <option hidden>Choisir ...</option>
                  <option value="0">Adolescent</option>
                  <option value="1">Adulte</option>
                </AvField>
              </Col>
              <Col lg="4">
              <AvField type="date" className="form-control" id="birthdate" name="birthdate" label="Date de naissance"
                  value={nc.birthdate} onChange={handleChange}
                  errorMessage="Date de naissance bligatoire !" validate={{ required: { value: true } }}
                />
              </Col>
              <Col lg="4">
              <AvField type="text" className="form-control" placeholder="Saisir le métier" id="metier"
                  name="metier" value={nc.metier} onChange={handleChange} label="Métier"
                />
              </Col>
            </Row>
            <Row>
              <Col lg="4">
              <AvField type="text" className="form-control"  id="adresse_postale" name="adresse_postale"
                  placeholder="Adresse postale..." value={nc.adresse_postale} onChange={handleChange} label="adresse postale"
                />
              </Col>
              <Col lg="3">
              <AdresseForm
                  setCodePostal={(codePostal, ville) =>
                    setNc({
                      ...nc,
                      code_postal: codePostal,
                      ville: ville,
                    })
                  }
                  handleReinitial={handleReinitial}
                />
              </Col>
              <Col lg="3">
              <AvField type="text" className="form-control" id="code_postal" name="code_postal" label="code postal"
                  placeholder="Entre le code postal" value={nc.code_postal} onChange={handleChange}
                />
              </Col>
            </Row>
            <Row>
              <Col lg="4">
              <AvField
                  type="email" className="form-control" placeholder="Saisir l'email" label="Email"
                  id="email" name="email" value={nc.email} onChange={handleChange}
                />
              </Col>
              <Col lg="4">
              <AvField
                  type="text" className="form-control" placeholder="Saisir le téléphone" label="Téléphone"
                  id="telephone" name="telephone" value={nc.telephone} onChange={handleChange}
                  errorMessage="N° de télphone obligatoire !" validate={{ required: { value: true } }}
                />
              </Col>
              <Col lg="1">&nbsp;</Col>
              <Col lg="3">
              <input
                  className="form-check-input"
                  name="is_livre"
                  type="checkbox"
                  defaultChecked={nc.is_livre}
                  onChange={handleLivre}
                  id="is_livre"
                />
                <label className="form-check-label" htmlFor="is_livre">
                  Livre 31 Jours
                </label>
              </Col>
            </Row>
            <Row>
              <Col lg="4">
              <AvField type="select" className="form-control" id="source_id" name="source_id" value={nc.source_id}
                  onChange={handleChange} label="Source"
                  errorMessage="Source obligatoire !" validate={{ required: { value: true } }}
                >
                  <option hidden>Choisir ...</option>
                  {sources.map((s) => (
                    <option key={s.id} value={s.id}>
                      {s.libelle}
                    </option>
                  ))}
                </AvField>
              </Col>
              <Col lg="4">
              <AvField type="date" className="form-control" placeholder="Date d'inscription" id="subscription_date"
                  name="subscription_date" value={nc.subscription_date} onChange={handleChange} label="Date d'enregistrement"
                  errorMessage="Date d'enregistrement obligatoire !" validate={{ required: { value: true } }}
                />
              </Col>
              <Col lg="4">
              <AvField type="select" className="form-control" id="secteur_gf" name="secteur_gf" value={nc.secteur_gf}
                  onChange={handleChange} label="Secteur GF">
                  <option value="">Choisir un secteur</option>
                  {Array.isArray(secteurs) &&
                    secteurs.map((s, index) => (
                      <option key={index} value={s}>
                        {s}
                      </option>
                    ))}
                </AvField>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="contained" color="primary" type="submit" className="ml-1 mr-1">Enregistrer</Button>
            <Button variant="contained" color="warning" onClick={handleReinitial} className="ml-1 mr-1">
              Réinitialiser
            </Button>
            <Button variant="contained" color="error" onClick={handleModalClose} className="ml-1 mr-1">
              Fermer
            </Button>
          </Modal.Footer>
        </AvForm>
      </Modal>
    </React.Fragment>
  );
};

export default DialogAjoutNc;
