import React from "react";
import { DataGrid, GridToolbar, frFR } from "@mui/x-data-grid";
import DialogModifierUser from "../../pages/users/dialog-modification-user";

const DataTableUsers = ({ users, sendUpdate }) => {
  const [pageSize, setPageSize] = React.useState(10);
  const columns = [
    {
      field: "id",
      sort: "desc",
      hide: true,
      headerName: "Id",
      flex: 0,
      headerClassName: "header-prog31",
      width: 40,
    },
    {
      field: "fullname",
      headerName: "Nom",
      flex: 0,
      headerClassName: "header-prog31",
      width: 250,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 0,
      headerClassName: "header-prog31",
      width: 230,
    },
    {
      field: "mobile_phone",
      headerName: "Telephone",
      flex: 0,
      headerClassName: "header-prog31",
      width: 150,
    },
    {
      field: "profil",
      hide: false,
      headerName: "Profil",
      flex: 2,
      headerClassName: "header-prog31",
    },
    {
      field: "genre",
      hide: true,
      headerName: "Genre",
      flex: 1,
      hideable: true,
      headerClassName: "header-prog31",
    },
    {
      field: "actif",
      hide: false,
      headerName: "Activé ?",
      flex: 0,
      width: 80,
      headerClassName: "header-prog31",
    },
    {
      field: "is_valide",
      hide: false,
      headerName: "Valide ?",
      flex: 0,
      width: 70,
      headerClassName: "header-prog31",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 250,
      headerClassName: "header-prog31",
      renderCell: (id) => (
        <React.Fragment>
          <DialogModifierUser user={id.row} id={id} sendUpdate={sendUpdate} />
        </React.Fragment>
      ),
    },
  ];

  return (
    <div style={{ height: 450, width: "100%" }}>
      <DataGrid
        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
        initialState={{
          sorting: {
            sortModel: [{ field: "id", sort: "desc" }],
          },
        }}
        components={{ Toolbar: GridToolbar }}
        rows={users}
        columns={columns}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[5, 10, 20]}
        pagination
        checkboxSelection
        rowHeight={40}
        columnHeaderHeight={40}
      />
    </div>
  );
};

export default DataTableUsers;
